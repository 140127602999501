export class beaconProfile {
  constructor(props) {
    this.email = decodeURIComponent(props.email) || null
    this.name = decodeURIComponent(props.name) || null
  }
}

export class beaconData {
  constructor(props) {
    this.brand = props.brand || null
    this.locale = props.locale || null
    this.os = props.os || null
    this.version = props.version || null
    this.appId = props.appId || null
    this.ref = props.ref || null
    this.transactionId = props.transactionId || null
    this.deviceCordova = props.deviceCordova || null
    this.deviceModel = props.deviceModel || null
    this.devicePlatform = props.devicePlatform || null
    this.deviceUuid = props.deviceUuid || null
    this.deviceVersion = props.deviceVersion || null
    this.deviceManufacturer = props.deviceManufacturer || null
  }
}

export function parseQueryStrings(text) {
  return text
    .split("&")
    .map(pair => pair.split("="))
    .reduce((prev, next) => {
      prev[next[0].replace("?", "")] = next[1]
      return prev
    }, {})
}
