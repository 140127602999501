import React from "react"

import styles from "./service-questions-list-item.module.css"

export default function ServiceQuestionsListItem({ title, text, id }) {
  return (
    <div id={id} className={styles.serviceQuestionsListItem}>
      <h3 className={styles.serviceQuestionsListItemTitle}>{title}</h3>
      <p className={styles.serviceQuestionsListItemText}>{text}</p>
    </div>
  )
}
