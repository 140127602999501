import React from "react"

import HelpIcon from "../../images/help-2.svg"
import WhatsAppIcon from "../../images/whatsapp.svg"

import styles from "./service-contact-box.module.css"

export default function ServiceContactBox({
  children,
  textHeader,
  textFooter,
  buttonText,
  buttonWhatsAppText,
  email,
}) {
  const openBeacon = () => {
    window.Beacon("open")
  }
  return (
    <div className={styles.serviceContactBox}>
      {children}
      <p className={styles.serviceContactBoxText}>{textHeader}</p>
      <p className={styles.serviceContactBoxText}>
        {textFooter}
        <br></br>
        <a href={`mailto:${email}`}>{email}</a>.
      </p>
      <div className={styles.serviceContactBoxButtons}>
        <a
          href="https://api.whatsapp.com/send?phone=12424220271"
          className={styles.serviceContactBoxButtonWhatsApp}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src={WhatsAppIcon}
            alt={`Smartpass ${buttonWhatsAppText}`}
            className={styles.serviceContactBoxButtonIcon}
          />
          <span className={styles.serviceContactBoxButtonText}>
            {buttonWhatsAppText}
          </span>
        </a>
        <button
          className={styles.serviceContactBoxButton}
          id="open-beacon-chat"
          onClick={openBeacon}
        >
          <img
            src={HelpIcon}
            alt={`Smartpass ${buttonText}`}
            className={styles.serviceContactBoxButtonIcon}
          />
          <span className={styles.serviceContactBoxButtonText}>
            {buttonText}
          </span>
        </button>
      </div>
    </div>
  )
}
