import React from "react"
import { Link as GatsbyLink } from "gatsby"

import styles from "./service-questions-more-link.module.css"

export default function ServiceQuestionsMoreLink({ text, url }) {
  return (
    <GatsbyLink
      id="services-questions-more"
      className={styles.serviceQuestionsMoreLink}
      to={url}
    >
      {text}
    </GatsbyLink>
  )
}
