import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import HelpIcon from "../images/help.svg"
import ContactIcon from "../images/headset.svg"

/* Components */
import SEO from "../components/seo/seo"
import Wrapper from "../components/wrapper/wrapper"
import HeroText from "../components/hero-text/hero-text"
import Container from "../components/container/container"
import Header from "../components/header/header"
import Content from "../components/content/content"
import Footer from "../components/footer/footer"
import LayoutService from "../components/layout-service/layout-service"
import ServiceQuestions from "../components/service-questions/service-questions"
import TitleWithIcon from "../components/title-with-icon/title-with-icon"
import ServiceQuestionsList from "../components/service-questions-list/service-questions-list"
import ServiceQuestionsListItem from "../components/service-questions-list-item/service-questions-list-item"
import ServiceQuestionsMoreLink from "../components/service-questions-more-link/service-questions-more-link"
import ServiceContact from "../components/service-contact/service-contact"
import ServiceContactBox from "../components/service-contact-box/service-contact-box"

import { beaconProfile, beaconData, parseQueryStrings } from "../utils/beacon"

export default function Ayuda({ location }) {
  const { t, i18n } = useTranslation()

  const PageTitle = t("pages.help.title")
  const PageDescription = t("meta.description")

  const Questions = t("pages.help.questions.items")

  const renderQuestions = []

  for (let [index, faq] of Questions.entries()) {
    index++
    renderQuestions.push(
      <ServiceQuestionsListItem
        key={faq.title}
        title={faq.title}
        text={faq.text}
        id={`services-questions-item-${index}`}
      />
    )
  }

  const faqUrl = "/faq/"

  useEffect(() => {
    window.scrollTo(0, 0)

    if (location.pathname === "/help/") {
      i18n.changeLanguage("en")
    }

    /* Data Params */
    if (window.location.search) {
      const locationMeta = parseQueryStrings(window.location.search)

      const metaProfile = new beaconProfile(locationMeta)
      const metaData = new beaconData(locationMeta)

      // Set Beacon Profile
      window.Beacon("identify", metaProfile)
      // Set Beacon Form Prefill
      window.Beacon("prefill", metaProfile)

      // Set Season Data (Brand/OS/Refer/Device/Locale/IDs)
      window.Beacon("session-data", metaData)
    }
  }, [i18n, location])

  return (
    <Wrapper>
      <SEO title={PageTitle} description={PageDescription} />
      <HeroText
        title={t("pages.help.hero.title")}
        text={t("pages.help.hero.text")}
        textKey="pages.help.hero.text"
        id="services-hero"
      >
        <Container>
          <Header classVariant="headerText" location={location} />
        </Container>
      </HeroText>
      <Content classVariant="contentPage">
        <Container>
          <LayoutService>
            <ServiceQuestions>
              <TitleWithIcon
                title={t("pages.help.questions.title")}
                icon={HelpIcon}
                id="services-questions-title"
              />
              <ServiceQuestionsList>
                {renderQuestions}
                <ServiceQuestionsMoreLink
                  text={t("pages.help.questions.more-text")}
                  url={faqUrl}
                />
              </ServiceQuestionsList>
            </ServiceQuestions>
            <ServiceContact>
              <ServiceContactBox
                textHeader={t("pages.help.contact.text-header")}
                textFooter={t("pages.help.contact.text-footer")}
                buttonText={t("pages.help.contact.button-text")}
                buttonWhatsAppText={t(
                  "pages.help.contact.button-whatsapp-text"
                )}
                email={t("pages.help.contact.email")}
              >
                <TitleWithIcon
                  title={t("pages.help.contact.title")}
                  icon={ContactIcon}
                />
              </ServiceContactBox>
            </ServiceContact>
          </LayoutService>
        </Container>
      </Content>
      <Footer />
    </Wrapper>
  )
}
