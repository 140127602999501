import React from "react"

import styles from "./title-with-icon.module.css"

export default function titleWithIcon({ title, icon, id }) {
  return (
    <h2 className={styles.titleWithIcon}>
      <img
        src={icon}
        alt={`Smartpass ${title}`}
        className={styles.titleWithIconImg}
      />
      <span id={id}>{title}</span>
    </h2>
  )
}
